import * as React from 'react';
import Link from 'next/link';
import TextInfo from '../../common/TextInfo';
import { Colors } from '../../../dls/Styleguide';
import {
  StyledChevronLeft,
  StyledChevronRight,
  StyledChevronRightSmall,
  StyledLinkContainer,
} from './DrawerLinkStyles';
import { StyledDivider } from './DrawerStyles';

type Props = {
  to: string;
  title: string;
  callback(): void;
  active?: boolean;
  nested?: boolean;
};

const getIcon = (active: boolean, nested: boolean) => {
  if (nested) {
    return <StyledChevronRightSmall color={Colors.DarkGray} />;
  }
  if (active) {
    return <StyledChevronLeft color={Colors.DarkGray} />;
  }
  return <StyledChevronRight color={Colors.DarkGray} />;
};

const DrawerLink = ({ to, title, callback, active = false, nested = false }: Props) => {
  return (
    <div>
      <Link href={to}>
        <StyledLinkContainer onClick={callback} role="button" tabIndex={0} onKeyUp={callback} nested={nested}>
          <TextInfo text={title} color={Colors.DarkGray} bold />
          {getIcon(active, nested)}
        </StyledLinkContainer>
      </Link>
      {!active && !nested && <StyledDivider />}
    </div>
  );
};

export default DrawerLink;

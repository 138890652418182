import * as React from 'react';
import { getIsFeatureEnabled } from './Utils';
import { FeatureFlag } from '../../types/Types';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  featureFlag: FeatureFlag;
};

const FeatureWrapper = ({ children, featureFlag }: Props) => {
  const isFeatureEnabled = getIsFeatureEnabled(featureFlag);

  if (
    (featureFlag === FeatureFlag.isIndexedSavingsAccountsEnabled && !isFeatureEnabled) ||
    !React.isValidElement(children)
  ) {
    return null;
  }

  return children;
};
export default FeatureWrapper;

import * as React from 'react';
import styled from 'styled-components';
import { circularPro } from './GlobalStyles';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const StyledMain = styled.main`
  font-family: var(--CircularPro), Arial, sans-serif;
`;

const FontWrapper = ({ children }: Props) => {
  const fontClassName = `${circularPro.variable}`;
  // TODO: Change this to something other than main and update global styles
  return <StyledMain className={fontClassName}>{children}</StyledMain>;
};
export default FontWrapper;

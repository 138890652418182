import { SurveySchema } from '@kvika/api-types';
import { SurveyAnswer, SurveyState } from '@kvika/audur-utils';
import { ActionMap } from '../types/Types';

export enum SurveyActionType {
  UpdateSurvey = 'UPDATE_SURVEY',
  UpdateAnswers = 'UPDATE_SURVEY_ANSWERS',
  UpdateCheckboxAnswers = 'UPDATE_SURVEY_CHECKBOX_ANSWERS',
  ClearAnswers = 'CLEAR_SURVEY_ANSWERS',
  RemoveQuestionAnswers = 'REMOVE_QUESTION_ANSWERS',
  UpdateAllAnswers = 'UPDATE_ALL_SURVEY_ANSWERS',
  UpdateMultiSelectAnswers = 'UPDATE_MULTI_SELECT_ANSWERS',
}

type SurveyPayload = {
  [SurveyActionType.UpdateSurvey]: SurveySchema;
  [SurveyActionType.UpdateAnswers]: SurveyAnswer;
  [SurveyActionType.UpdateCheckboxAnswers]: SurveyAnswer;
  [SurveyActionType.ClearAnswers]: undefined;
  [SurveyActionType.RemoveQuestionAnswers]: number;
  [SurveyActionType.UpdateAllAnswers]: SurveyAnswer[];
  [SurveyActionType.UpdateMultiSelectAnswers]: SurveyAnswer[];
};

export type SurveyActions = ActionMap<SurveyPayload>[keyof ActionMap<SurveyPayload>];
export const surveyReducer = (state: SurveyState, action: SurveyActions): SurveyState => {
  switch (action.type) {
    case SurveyActionType.UpdateSurvey: {
      return {
        ...state,
        survey: action.payload,
      };
    }

    // For questions that can only have one answer, e.g. RadioList
    case SurveyActionType.UpdateAnswers: {
      const updatedAnswers = state.answers.filter((answer) => answer.questionId !== action.payload.questionId);
      updatedAnswers.push(action.payload);
      return {
        ...state,
        answers: updatedAnswers,
      };
    }

    // For questions that can have multiple answers, e.g. CheckboxList
    case SurveyActionType.UpdateCheckboxAnswers: {
      const choice = action.payload;
      const isSelected = state.answers.some(
        (answer) => answer.questionId === choice.questionId && answer.choiceId === choice.choiceId
      );
      const updatedAnswers = state.answers.filter(
        (answer) => !(answer.questionId === choice.questionId && answer.choiceId === choice.choiceId)
      );
      if (!isSelected) {
        updatedAnswers.push(choice);
      }
      return {
        ...state,
        answers: updatedAnswers,
      };
    }

    case SurveyActionType.ClearAnswers: {
      return {
        ...state,
        answers: [],
      };
    }

    case SurveyActionType.RemoveQuestionAnswers: {
      const questionId = action.payload;
      return {
        ...state,
        answers: state.answers.filter((answer) => answer.questionId !== questionId),
      };
    }

    case SurveyActionType.UpdateAllAnswers: {
      return {
        ...state,
        answers: action.payload,
      };
    }

    case SurveyActionType.UpdateMultiSelectAnswers: {
      const choices = action.payload;
      const answersWithoutCurrentQuestion = state.answers.filter(
        (answer) => answer.questionId !== choices[0].questionId
      );
      return {
        ...state,
        answers: answersWithoutCurrentQuestion.concat(choices),
      };
    }

    default: {
      return state;
    }
  }
};

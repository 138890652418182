import * as React from 'react';
import { GeneralStrings, NewAccountStrings } from '@kvika/audur-strings';
import { hasAccountValue } from '@kvika/audur-utils';
import { AccountNumberSchema, ValidateAccountResponseSchema } from '@kvika/audur-api-types';
import { ApiError as AudurApiError } from '@kvika/audur-api-client-v2';
import { isBankAccountValidFormat } from '@kvika/string-utils';
import AudurWebApiClient from '../../audurAPI/AudurWebApiClient';
import AudurTextInput from './AudurTextInput';
import TextInfo from './TextInfo';
import { Colors, GridRem } from '../../dls/Styleguide';
import { BankAccountInputType } from '../../types/UITypes';
import { SentryErrors } from '../../utils/SentryWrapper';
import { errorHandling, useAppContext } from '../../store/AppContext';
import { getParsedPasteData, prependZerosToString } from '../../utils/Utils';

type Props = {
  apiClient: AudurWebApiClient;
  bankAccount: AccountNumberSchema;
  onSetIsLoading?(isLoading: boolean): void;
  validationFunction?(isFormValid: boolean, isButtonInCancelState: boolean): void;
  onInputChange(input: string, type: BankAccountInputType): void;
  onBankAccountChange?(bankAccount: AccountNumberSchema): void;
};

const BankAccountInput = ({
  apiClient,
  bankAccount,
  onSetIsLoading,
  validationFunction,
  onInputChange,
  onBankAccountChange,
}: Props) => {
  const { dispatch } = useAppContext();
  const [errorText, setErrorText] = React.useState<string>('');
  const [error, setError] = React.useState<boolean>(false);

  const bankInputRef = React.useRef<HTMLInputElement>(null);
  const branchInputRef = React.useRef<HTMLInputElement>(null);
  const accountInputRef = React.useRef<HTMLInputElement>(null);

  const typingDelayRef = React.useRef<number>();

  const getValidationInfo = (accountNumber: AccountNumberSchema) => {
    onSetIsLoading && onSetIsLoading(true);
    setErrorText('');

    apiClient
      .getAudurWebApiClient()
      .validateAccount(accountNumber)
      .then((validation: ValidateAccountResponseSchema) => {
        setErrorText(validation.isValid ? '' : NewAccountStrings.ChooseYourBankAccount);
        setError(!validation.isValid);
        validationFunction && validationFunction(validation.isValid, false);
        onSetIsLoading && onSetIsLoading(false);
      })
      .catch((error: AudurApiError) => {
        setErrorText(GeneralStrings.ErrorMessage);
        setError(true);
        validationFunction && validationFunction(false, false);
        onSetIsLoading && onSetIsLoading(false);
        errorHandling({
          dispatch,
          error,
          displayErrorToUser: false,
          sentryErrorString: SentryErrors.AccountValidationError,
        });
      });
  };

  const validateAccount = (accountNumber: AccountNumberSchema) => {
    if (typingDelayRef.current) {
      window.clearTimeout(typingDelayRef.current);
    }

    const isAccountValid = hasAccountValue(accountNumber);

    if (isAccountValid) {
      onSetIsLoading && onSetIsLoading(true);
      setErrorText('');
      setError(false);
      // Wait a bit for user to finish typing before validating bank account
      typingDelayRef.current = window.setTimeout(() => {
        getValidationInfo(accountNumber);
      }, 2000);
    } else {
      onSetIsLoading && onSetIsLoading(false);
      setErrorText('');
      setError(false);
      validationFunction && validationFunction(isAccountValid, false);
    }
  };

  const handlePasteBankInput = (event: React.ClipboardEvent<HTMLInputElement>, type: BankAccountInputType) => {
    event.preventDefault();

    const { parsedcopiedText, dashCount, bankValue, branchValue, accountValue } = getParsedPasteData(event);

    const isInvalidDashCount = dashCount === 0 || dashCount > 2;
    const hasValidLength = bankValue?.length <= 4 && branchValue?.length <= 2 && accountValue?.length <= 6;

    if (onBankAccountChange && isBankAccountValidFormat(event.clipboardData?.getData('text'))) {
      switch (type) {
        case BankAccountInputType.Bank:
          if (isInvalidDashCount || !hasValidLength) {
            onBankAccountChange({
              bank: parsedcopiedText.substring(0, 4),
              branch: parsedcopiedText.substring(4, 6),
              account: parsedcopiedText.substring(6, 12),
            });
            validateAccount({
              ...bankAccount,
              bank: parsedcopiedText.substring(0, 4),
              branch: parsedcopiedText.substring(4, 6),
              account: parsedcopiedText.substring(6, 12),
            });
          } else if (dashCount === 2 && hasValidLength) {
            onBankAccountChange({
              bank: bankValue,
              branch: branchValue,
              account: accountValue,
            });
            validateAccount({ ...bankAccount, bank: bankValue, branch: branchValue, account: accountValue });
          }
          break;

        case BankAccountInputType.Branch:
          onBankAccountChange({
            ...bankAccount,
            branch: parsedcopiedText.substring(0, 2),
            account: parsedcopiedText.substring(2, 8),
          });
          validateAccount({
            ...bankAccount,
            branch: parsedcopiedText.substring(0, 2),
            account: parsedcopiedText.substring(2, 8),
          });
          break;

        case BankAccountInputType.Account:
          onBankAccountChange({ ...bankAccount, account: parsedcopiedText });
          validateAccount({ ...bankAccount, account: parsedcopiedText });
          break;

        default:
          break;
      }
    }
  };

  // We validate bank account after mounting component in case
  // it already contains an incorrect account
  React.useEffect(() => {
    if (hasAccountValue(bankAccount)) {
      getValidationInfo(bankAccount);
    } else {
      // If bank account is empty, it's invalid
      validationFunction && validationFunction(false, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ marginRight: GridRem.Gridx16 }}>
          <AudurTextInput
            id="bank"
            label={NewAccountStrings.Bank}
            style={{ display: 'flex', flex: 1, marginRight: GridRem.Gridx16, minWidth: '4rem' }}
            placeholder="0000"
            maxLength={4}
            ref={bankInputRef}
            onChange={(bank) => {
              if (!isBankAccountValidFormat(bank)) return;
              validateAccount({ ...bankAccount, bank });
              onInputChange(bank, BankAccountInputType.Bank);
            }}
            onBlur={() => {
              onInputChange(prependZerosToString(bankAccount.bank, 4), BankAccountInputType.Bank);
            }}
            onFocus={() => {
              setErrorText('');
              setError(false);
            }}
            error={error}
            type="tel"
            value={bankAccount.bank}
            onPaste={(event) => handlePasteBankInput(event, BankAccountInputType.Bank)}
            width="100%"
            name="bankInput"
            autoFocus
          />
        </div>
        <div style={{ marginRight: GridRem.Gridx16 }}>
          <AudurTextInput
            id="branch"
            label={NewAccountStrings.HB}
            style={{ display: 'flex', flex: 1, marginRight: GridRem.Gridx16, minWidth: '2rem' }}
            placeholder="00"
            maxLength={2}
            ref={branchInputRef}
            onChange={(branch) => {
              if (!isBankAccountValidFormat(branch)) return;
              validateAccount({ ...bankAccount, branch });
              onInputChange(branch, BankAccountInputType.Branch);
            }}
            onBlur={() => {
              prependZerosToString(bankAccount.branch, 2);
              onInputChange(prependZerosToString(bankAccount.branch, 2), BankAccountInputType.Branch);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Backspace' && bankAccount.branch.length === 0) {
                bankInputRef.current?.focus();
              }
            }}
            onFocus={() => setErrorText('')}
            error={error}
            type="tel"
            value={bankAccount.branch}
            onPaste={(event) => handlePasteBankInput(event, BankAccountInputType.Branch)}
            width="100%"
            name="branchInput"
          />
        </div>
        <div style={{ marginRight: GridRem.Gridx16 }}>
          <AudurTextInput
            id="account"
            label={NewAccountStrings.BankAccountNumber}
            style={{ display: 'flex', flex: 2 }}
            placeholder="000000"
            maxLength={6}
            ref={accountInputRef}
            onChange={(account) => {
              if (!isBankAccountValidFormat(account)) return;
              validateAccount({ ...bankAccount, account });
              onInputChange(account, BankAccountInputType.Account);
            }}
            onBlur={() => {
              prependZerosToString(bankAccount.account, 6);
              onInputChange(prependZerosToString(bankAccount.account, 6), BankAccountInputType.Account);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Backspace' && bankAccount.account.length === 0) {
                branchInputRef.current?.focus();
              }
            }}
            onFocus={() => setErrorText('')}
            error={error}
            type="tel"
            value={bankAccount.account}
            onPaste={(event) => handlePasteBankInput(event, BankAccountInputType.Account)}
            width="100%"
            name="accountInput"
          />
        </div>
      </div>
      {errorText.length > 0 && (
        <TextInfo text={NewAccountStrings.ChooseYourBankAccount} style={{ color: Colors.Red }} />
      )}
    </div>
  );
};

export default BankAccountInput;

import styled from 'styled-components';

export const StyledAccountsPageloader = styled.div`
  display: block;

  @media all and (max-width: 700px) {
    display: none;
  }
`;

export const StyledMobileAccountsPageLoader = styled.div`
  display: none;

  @media all and (max-width: 700px) {
    display: block;
  }
`;

export const StyledAccountsLoader = styled.div`
  display: block;

  @media all and (max-width: 700px) {
    display: none;
  }
`;

export const StyledAccountsMobileLoader = styled.div`
  display: none;

  @media all and (max-width: 700px) {
    display: block;
  }
`;

export const StyledhighlightCardLoader = styled.div`
  display: block;

  @media all and (max-width: 700px) {
    display: none;
  }
`;

export const StyledhighlightCardMobileLoader = styled.div`
  display: none;

  @media all and (max-width: 700px) {
    display: block;
  }
`;

export const StyledInterestSummaryLoader = styled.div`
  display: block;

  @media all and (max-width: 700px) {
    display: none;
  }
`;

export const StyledInterestSummaryMobileLoader = styled.div`
  display: none;

  @media all and (max-width: 700px) {
    display: block;
  }
`;

export const StyledTransactionsLoader = styled.div`
  display: block;

  @media all and (max-width: 700px) {
    display: none;
  }
}
`;

export const StyledTransactionsMobileLoader = styled.div`
  display: none;

  @media all and (max-width: 700px) {
    display: block;
  }
}
`;

import * as React from 'react';

import styled from 'styled-components';
import { Colors, FontSizeRem } from '../../../dls/Styleguide';
import { LinkTarget } from '../../../types/Types';

type Props = {
  url: string;
  title: string;
};

const StyledLink = styled.a`
  font-size: ${FontSizeRem.Body};
  line-height: 2;
  letter-spacing: 0.5px;
  display: block;
  text-decoration: none;
  color: ${Colors.Beige};

  &:hover {
    text-decoration: underline;
  }
`;

const FooterLink = ({ url, title }: Props) => {
  return (
    <StyledLink href={url} target={LinkTarget.Blank} rel="noopener noreferrer">
      {title}
    </StyledLink>
  );
};

export default FooterLink;

import { withWindowDefined } from './BrowserStorageUtils';

export enum SessionStorageKeys {
  AUDUR_USER = 'audur_user',
  EXTERNAL_ID = 'external_id',
  USER_ACCESS = 'user_access',
}

export const getSessionStorageItem = (key: SessionStorageKeys): string | undefined => {
  return withWindowDefined(() => window.sessionStorage.getItem(key)) ?? undefined;
};

export const setSessionStorageItem = (key: SessionStorageKeys, value: string): void => {
  withWindowDefined(() => window.sessionStorage.setItem(key, value));
};

export const removeSessionStorageItem = (key: SessionStorageKeys): void => {
  withWindowDefined(() => window.sessionStorage.removeItem(key));
};

export const getExternalId = () => {
  return getSessionStorageItem(SessionStorageKeys.EXTERNAL_ID);
};

import { Colors } from '@kvika/audur-theme';
import styled from 'styled-components';
import { GridRem } from '../../../dls/Styleguide';
import ClickableButton from '../../common/ClickableButton/ClickableButton';

export const StyledIconButton = styled(ClickableButton)`
  padding: ${GridRem.Gridx4};
  background-color: ${Colors.Yellow};
  margin-right: ${GridRem.Gridx16};
  border-radius: 50%;
`;

export const StyledIconButtonText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  height: 35px;
  width: 35px;
  color: ${Colors.DarkGray};
`;

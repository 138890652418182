import { ExtraSavingsAccountsStrings, GeneralStrings } from '@kvika/audur-strings';

export enum Route {
  Home = '/home',
  Login = '/',
  CreateAccount = '/create-account',
  IsPEP = '/is-pep',
  PEPUser = '/pep-user',
  Settings = '/settings',
  SavingsAccount = '/savings-account',
  ExtraSavingsAccountsDefault = '/savings-accounts',
  ExtraSavingsAccountsCreate = '/savings-accounts/create-account',
  TermDepositAccountsDefault = '/term-deposit-accounts',
  TermDepositAccountsCreate = '/term-deposit-accounts/create-account',
  FutureAccountsDefault = '/future-accounts',
  FutureAccountsCreate = '/future-accounts/create-account',
  IndexedSavingsAccountsDefault = '/indexed-accounts',
  IndexedSavingsAccountsCreate = '/indexed-accounts/create-account',
  ConfirmAML = '/confirm-aml',
  OverviewAML = '/overview-aml',
  ChildrensAccounts = '/childrens-accounts',
  Transfer = '/transfer',
  ClosedAccounts = '/closed-accounts',
  ChooseAccount = '/choose-account',
  LegalEntityOnboarding = '/legal-entity-onboarding',
  IdentityConfirmation = '/identity-confirmation',
  ThankYouForConfirming = '/thank-you-for-confirming',
}

export type NavLinks = {
  Home: Route;
  Login: Route;
  CreateAccount: Route;
  IsPEP: Route;
  PEPUser: Route;
  Settings: Route;
  SavingsAccount: Route;
  ExtraSavingsAccounts: {
    Default: Route;
    Create: Route;
  };
  TermDepositAccounts: {
    Default: Route;
    Create: Route;
  };
  FutureAccounts: {
    Default: Route;
    Create: Route;
  };
  IndexedSavingsAccounts: {
    Default: Route;
    Create: Route;
  };
  ConfirmAML: Route;
  OverviewAML: Route;
  ChildrensAccounts: Route;
  Transfer: Route;
  ClosedAccounts: Route;
  ChooseAccount: Route;
  LegalEntityOnboarding: Route;
  IdentityConfirmation: Route;
  ThankYouForConfirming: Route;
};

export const Links: NavLinks = {
  Home: Route.Home,
  Login: Route.Login,
  CreateAccount: Route.CreateAccount,
  IsPEP: Route.IsPEP,
  PEPUser: Route.PEPUser,
  Settings: Route.Settings,
  SavingsAccount: Route.SavingsAccount,
  ExtraSavingsAccounts: {
    Default: Route.ExtraSavingsAccountsDefault,
    Create: Route.ExtraSavingsAccountsCreate,
  },
  TermDepositAccounts: {
    Default: Route.TermDepositAccountsDefault,
    Create: Route.TermDepositAccountsCreate,
  },
  FutureAccounts: {
    Default: Route.FutureAccountsDefault,
    Create: Route.FutureAccountsCreate,
  },
  IndexedSavingsAccounts: {
    Default: Route.IndexedSavingsAccountsDefault,
    Create: Route.IndexedSavingsAccountsCreate,
  },
  ConfirmAML: Route.ConfirmAML,
  OverviewAML: Route.OverviewAML,
  ChildrensAccounts: Route.ChildrensAccounts,
  Transfer: Route.Transfer,
  ClosedAccounts: Route.ClosedAccounts,
  ChooseAccount: Route.ChooseAccount,
  LegalEntityOnboarding: Route.LegalEntityOnboarding,
  IdentityConfirmation: Route.IdentityConfirmation,
  ThankYouForConfirming: Route.ThankYouForConfirming,
};

export enum TransactionScreenSlides {
  Transactions = 'transactions',
  Rename = 'rename',
  Delete = 'delete',
}

export const DOCUMENT_SIGNING_DELAY = 11000;
export const MAX_POLLING_TIME = 120000;

export enum LottieAnimation {
  CatCafe = '/lottie/cat_cafe.json',
  FireWorks = '/lottie/fireworks.json',
  Hourglass = '/lottie/hourglass.json',
  LoaderBalls = '/lottie/loader_balls.json',
  LoaderShapes = '/lottie/loader_shapes.json',
  ManScrolling = 'lottie/man_scrolling.json',
  SavingMoney = '/lottie/saving_money.json',
  Snowboard = '/lottie/snowboard.json',
  Summerhouse = '/lottie/summerhouse.json',
}

export const DEFAULT_NAV_ACCOUNT_LINKS = [
  { path: Links.SavingsAccount, title: GeneralStrings.SavingsAccount },
  { path: Links.ExtraSavingsAccounts.Default, title: ExtraSavingsAccountsStrings.SavingsAccounts },
  { path: Links.TermDepositAccounts.Default, title: GeneralStrings.TermDepositAccounts },
];

export const loggedOutNavLinks: Route[] = [
  Links.Login,
  Links.CreateAccount,

  Links.ConfirmAML,
  Links.OverviewAML,

  Links.LegalEntityOnboarding,
  Links.ChooseAccount,

  Links.IdentityConfirmation,
  Links.ThankYouForConfirming,
];

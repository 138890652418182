import * as React from 'react';
import Image from 'next/image';
import lottie from 'lottie-web/build/player/lottie_light';
import { useRouter } from 'next/router';
import AboutColumn from './AboutColumn';
import TermsColumn from './TermsColumn';
import ContactColumn from './ContactColumn';
import { Links } from '../../../dls/DataConstants';
import {
  StyledFooter,
  StyledFooterContainer,
  StyledLogo,
  StyledTopDivider,
  StyledTextColumnContainer,
  StyledImageColumn,
} from './FooterStyles';

const Footer = () => {
  const router = useRouter();

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#summerhouse') as Element,
      path: '/lottie/summerhouse.json',
    });

    // Return clean up function here
    return () => lottie.destroy();
  }, []);

  const isCreateAccount = router.pathname.startsWith(Links.CreateAccount);

  if (isCreateAccount) return null;

  return (
    <StyledFooter>
      <StyledTopDivider />
      <StyledLogo>
        <Image height={55} width={100} alt="logo" src="/svg/audur_logo.svg" />
      </StyledLogo>
      <StyledFooterContainer>
        <StyledImageColumn>
          <div id="summerhouse" />
        </StyledImageColumn>
        <StyledTextColumnContainer>
          <AboutColumn />
          <TermsColumn />
          <ContactColumn />
        </StyledTextColumnContainer>
      </StyledFooterContainer>
    </StyledFooter>
  );
};

export default Footer;

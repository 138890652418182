import * as React from 'react';
import { FooterStrings } from '@kvika/audur-strings';
import FooterLink from './FooterLink';
import { StyledAboutColumn } from './FooterStyles';

const AboutColumn = () => {
  return (
    <StyledAboutColumn>
      <FooterLink url="https://www.audur.is/sparnadur" title={FooterStrings.Savings} />
      <FooterLink url="https://www.audur.is/um-audi" title={FooterStrings.About} />
      <FooterLink url="https://www.audur.is/algengar-spurningar" title={FooterStrings.FAQ} />
    </StyledAboutColumn>
  );
};

export default AboutColumn;

import * as React from 'react';
import { ProfileStrings } from '@kvika/audur-strings';
import FooterLink from './FooterLink';
import { StyledTermsColumn } from './FooterStyles';

const TermsColumn = () => {
  return (
    <StyledTermsColumn>
      <FooterLink url="https://audur.is/skilmalar-personuverndarstefna" title={ProfileStrings.TermsAndConditions} />
    </StyledTermsColumn>
  );
};

export default TermsColumn;

import { DisruptionTypeEnum } from '@kvika/api-types';
import AudurClient from '@kvika/audur-api-client-v2';
import moment, { Moment } from 'moment';
import { ActionType, AppActions } from '../store/Reducers';

type ClientConfig = {
  expiration?: Moment;
  dispatch?: React.Dispatch<AppActions>;
  authToken?: string;
};
class AudurWebApiClient {
  private audurClient: AudurClient;

  private expirationDate?: Moment;

  constructor({ expiration, dispatch, authToken }: ClientConfig) {
    this.expirationDate = expiration;
    this.audurClient = new AudurClient({
      apiUrl: process.env.NEXT_PUBLIC_AUDUR_API_URL ?? '',
      apiVersion: '2023-02-21_1', // Children accounts version
      storeAuthToken: () => {
        // Leave empty as we don't need to store auth token in web, it's done via secure cookies
      },
      onResponseHeaders: (headers) => {
        if (dispatch) {
          dispatch({
            type: ActionType.UpdateServiceStatusMode,
            payload: {
              serviceStatus: headers['x-disruption-type'] as DisruptionTypeEnum,
              message: headers['x-disruption-message'],
              showAfter: new Date(),
            },
          });
        }
      },
      authToken,
    });
  }

  public getAudurWebApiClient() {
    return this.audurClient;
  }

  public isExpired() {
    return moment().isAfter(this.expirationDate);
  }
}
export default AudurWebApiClient;

import { GeneralStrings } from '@kvika/audur-strings';
import { LottieAnimation, Route } from '../dls/DataConstants';
import { Colors } from '../dls/Styleguide';

export enum BankAccountInputType {
  Bank = 'bank',
  Branch = 'branch',
  Account = 'account',
}

export interface ModalItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lottieSource: LottieAnimation;
  title: string;
  description: string;
  forwardLink?: Route;
  callToActionText?: GeneralStrings | string;
  preTitle?: string;
  backgroundColor?: Colors;
  showBackButton?: boolean;
  hasActionButton?: boolean; // The forward button is a call to action, such as creating a new account
}

export interface IBasicUser {
  name: string;
  email: string;
  socialSecurity: string;
  isPreFilled: boolean;
  phone?: string;
}

export type TooltipPlacement =
  | 'bottom'
  | 'left'
  | 'right'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end';

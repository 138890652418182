import React from 'react';
import { StyledDrawer } from './DrawerStyles';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  isOpen: boolean;
};

const Drawer = ({ children, isOpen }: Props) => {
  return <StyledDrawer isOpen={isOpen}>{children}</StyledDrawer>;
};
export default Drawer;

import styled, { css } from 'styled-components';
import { Colors, GridRem } from '../../../dls/Styleguide';

export const StyledFooter = styled.footer`
  display: flex;
  padding: 0 ${GridRem.Gridx32};
  background-color: ${Colors.DarkGray};
  height: 421px;

  @media all and (max-width: 72.5em) {
    padding: 0;
  }
`;

export const StyledTopDivider = styled.div`
  position: absolute;
  left: 0;
  border-top: 2px solid ${Colors.CardGray};
  width: 100%;
`;

export const StyledLogo = styled.div`
  position: absolute;
  margin-top: ${GridRem.Gridx16};
  margin-left: ${GridRem.Gridx32};

  @media all and (max-width: 36em) {
    margin-left: ${GridRem.Gridx8};
  }
`;

export const StyledFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${GridRem.Gridx64} ${GridRem.Gridx32} ${GridRem.Gridx32} ${GridRem.Gridx32};
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;

  @media all and (max-width: 36em) {
    padding: ${GridRem.Gridx64} ${GridRem.Gridx16} ${GridRem.Gridx32} ${GridRem.Gridx16};
  }
`;

export const StyledTextColumnContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  order: 1;
  justify-content: space-evenly;

  @media all and (max-width: 72.5em) {
    display: flex;
    flex-direction: column;
    order: 0;
    margin-top: 20px;
  }
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledImageColumn = styled.div`
  order: 0;
  max-width: 20rem;

  @media all and (max-width: 72.5em) {
    order: 1;
  }

  @media all and (max-width: 36em) {
    display: none;
  }
`;

const footerColumn = css`
  min-width: 14rem;
  margin-bottom: ${GridRem.Gridx32};
`;

export const StyledAboutColumn = styled.div`
  ${footerColumn}
  order: 0;

  @media all and (max-width: 72.5em) {
    order: 1;
  }
`;

export const StyledTermsColumn = styled.div`
  ${footerColumn}
  order: 1;

  @media all and (max-width: 72.5em) {
    order: 2;
  }
`;

export const StyledContactColumn = styled.div`
  ${footerColumn}
  order: 2;

  @media all and (max-width: 72.5em) {
    order: 0;
  }
`;

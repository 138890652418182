import * as React from 'react';

import styled from 'styled-components';
import { GeneralStrings, TransactionStrings } from '@kvika/audur-strings';

import { Menu } from '@kvika/audur-icons';
import { Colors } from '../../../dls/Styleguide';
import { Links } from '../../../dls/DataConstants';
import ClickableButton from '../../common/ClickableButton/ClickableButton';
import Drawer from '../drawer/Drawer';
import DrawerHeader from '../drawer/DrawerHeader';
import AccountLinks from './AccountLinks';
import { StyledDrawerContent } from '../drawer/DrawerStyles';
import DrawerLink from '../drawer/DrawerLink';

type Props = {
  userName?: string;
  showFutureAccountsLink?: boolean;
  showClosedAccountsLink?: boolean;
};

const StyledDrawerContainer = styled.div`
  @media all and (min-width: 45em) {
    display: none;
  }
`;

const MenuDrawer = ({ userName, showFutureAccountsLink = false, showClosedAccountsLink = false }: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
  const [isAccordianOpen, setIsAccordianOpen] = React.useState<boolean>(false);

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <StyledDrawerContainer>
      <ClickableButton onClick={() => setIsDrawerOpen(true)}>
        <Menu color={Colors.Beige} />
      </ClickableButton>
      <Drawer isOpen={isDrawerOpen}>
        <StyledDrawerContent>
          <DrawerHeader userName={userName} handleCloseDrawer={handleCloseDrawer} />
          <div>
            <DrawerLink to={Links.Transfer} title={TransactionStrings.Transfer} callback={handleCloseDrawer} />
            <DrawerLink
              to={Links.Transfer}
              title={GeneralStrings.Accounts}
              callback={() => setIsAccordianOpen(!isAccordianOpen)}
              active={isAccordianOpen}
            />
            <AccountLinks
              showFutureAccountsLink={showFutureAccountsLink}
              showClosedAccountsLink={showClosedAccountsLink}
              handleCloseDrawer={handleCloseDrawer}
              isOpen={isAccordianOpen}
            />
          </div>
        </StyledDrawerContent>
      </Drawer>
    </StyledDrawerContainer>
  );
};

export default MenuDrawer;

import { withWindowDefined } from './BrowserStorageUtils';

export enum LocalStorageKeys {
  USER_FIRST_SAW_RECURRING_AML_AT = 'userFirstSawRecurringAMLAt',
  USER_LAST_SAW_RECURRING_AML_AT = 'userLastSawRecurringAMLAt',
  HAS_SEEN_DISRUPTION_MODAL = 'hasSeenDisruptionModal',
  HAS_SEEN_CHILDREN_ACCOUNT_ANNOUNCEMENT = 'hasSeenChildrenAccountAnnouncementKey',
  HAS_SEEN_INDEXED_ACCOUNT_ANNOUNCEMENT = 'hasSeenIndexedAccountAnnouncementKey',
  HAS_SEEN_EXTRA_SAVINGS_ACCOUNT_ANNOUNCEMENT = 'hasSeenExtraSavingsAccountAnnouncement',
}

export const getLocalStorageItem = (key: LocalStorageKeys): string | undefined => {
  return withWindowDefined(() => window.localStorage.getItem(key)) ?? undefined;
};

export const setLocalStorageItem = (key: LocalStorageKeys, value: string): void => {
  withWindowDefined(() => window.localStorage.setItem(key, value));
};

export const removeLocalStorageItem = (key: LocalStorageKeys): void => {
  withWindowDefined(() => window.localStorage.removeItem(key));
};

export const getHasSeenChildrenAccountAnnouncementAd = (): boolean => {
  return getLocalStorageItem(LocalStorageKeys.HAS_SEEN_CHILDREN_ACCOUNT_ANNOUNCEMENT) === 'true';
};
export const setHasSeenChildrenAccountAnnouncementAd = (): void => {
  setLocalStorageItem(LocalStorageKeys.HAS_SEEN_CHILDREN_ACCOUNT_ANNOUNCEMENT, 'true');
};

export const getHasSeenIndexedSavingsAccountAnnouncementAd = (): boolean => {
  return getLocalStorageItem(LocalStorageKeys.HAS_SEEN_INDEXED_ACCOUNT_ANNOUNCEMENT) === 'true';
};
export const setHasSeenIndexedSavingsAccountAnnouncementAd = (): void => {
  setLocalStorageItem(LocalStorageKeys.HAS_SEEN_INDEXED_ACCOUNT_ANNOUNCEMENT, 'true');
};

export const getUserFirstSawRecurringAMLAt = (): Date | undefined => {
  const stringOrUndefined = getLocalStorageItem(LocalStorageKeys.USER_FIRST_SAW_RECURRING_AML_AT);
  return stringOrUndefined ? new Date(stringOrUndefined) : undefined;
};

export const setUserFirstSawRecurringAMLAt = (date: Date): void => {
  setLocalStorageItem(LocalStorageKeys.USER_FIRST_SAW_RECURRING_AML_AT, date.toString());
};

export const getUserLastSawRecurringAMLAt = (): Date | undefined => {
  const stringOrUndefined = getLocalStorageItem(LocalStorageKeys.USER_LAST_SAW_RECURRING_AML_AT);
  return stringOrUndefined ? new Date(stringOrUndefined) : undefined;
};

export const setUserLastSawRecurringAMLAt = (date: Date): void => {
  setLocalStorageItem(LocalStorageKeys.USER_LAST_SAW_RECURRING_AML_AT, date.toString());
};

export const clearUserFirstSawRecurringAMLAt = (): void => {
  removeLocalStorageItem(LocalStorageKeys.USER_FIRST_SAW_RECURRING_AML_AT);
};

export const getHasSeenExtraSavingsAccountAnnouncementAd = (): boolean => {
  return getLocalStorageItem(LocalStorageKeys.HAS_SEEN_EXTRA_SAVINGS_ACCOUNT_ANNOUNCEMENT) === 'true';
};

export const setHasSeenExtraSavingsAccountAnnouncementAd = (): void => {
  setLocalStorageItem(LocalStorageKeys.HAS_SEEN_EXTRA_SAVINGS_ACCOUNT_ANNOUNCEMENT, 'true');
};

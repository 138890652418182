import { FeatureFlag } from '../../types/Types';

export const getIsFeatureEnabled = (featureFlag?: FeatureFlag) => {
  switch (featureFlag) {
    // Remove this case once the feature is enabled on production (https://github.com/kvika/audur-online-bank/milestone/3)
    case FeatureFlag.isIndexedSavingsAccountsEnabled:
      return process.env.NEXT_PUBLIC_INDEXED_SAVINGS_ACCOUNTS_FEATURE_ENBALED === 'true';
    default:
      return false;
  }
};

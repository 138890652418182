import * as React from 'react';
import { GeneralStrings, IndexedSavingsAccountsStrings, TransactionStrings } from '@kvika/audur-strings';
import { toIskCurrencyString } from '@kvika/string-utils';
import { stringToValidInteger } from '@kvika/audur-utils';
import { getTransferInputMaxLength } from '../../../utils/Utils';
import AudurTextInput from '../AudurTextInput';
import { FontSizeRem } from '../../../dls/Styleguide';
import { StyledTextInfoWrapper } from '../../styledComponents/TextInfoWrapperStyles';
import { MAX_AMOUNT } from '../../../utils/Constants';
import { StyledInputContainer, StyledInputLabel, StyledStatusText } from './TransferInputStyles';

type Props = {
  balance?: number;
  amount: string;
  setAmount(amount: string): void;
  autoFocus?: boolean;
  shouldIgnoreTab?: boolean;
  isIndexedSourceAccount?: boolean;
};

export const getDiffBalance = (transferAmount: number, accountBalance: number) => {
  const diffAmount = accountBalance - transferAmount;
  return diffAmount;
};

const TransferInput = React.forwardRef(
  (
    { balance, amount, setAmount, autoFocus, shouldIgnoreTab, isIndexedSourceAccount }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [diffBalance, setDiffBalance] = React.useState<number | undefined>(balance);
    const [shouldShowBalance, setShouldShowBalance] = React.useState(true);

    React.useEffect(() => {
      const transferAmount = stringToValidInteger(amount);
      if (balance !== undefined && Number.isInteger(transferAmount)) {
        const diffBalance = balance - transferAmount;
        setDiffBalance(diffBalance);
      }
    }, [amount, balance]);

    React.useEffect(() => {
      if (balance) {
        const transferAmount = stringToValidInteger(amount);
        const diffBalance = Number.isInteger(transferAmount) ? getDiffBalance(transferAmount, balance) : balance;
        setDiffBalance(diffBalance);
        if (stringToValidInteger(amount) > 0) {
          // TODO: Remove the double bang operator (!!) and regression test
          setShouldShowBalance(!!(parseFloat(amount) < MAX_AMOUNT));
        }
      }
    }, [amount, balance]);

    return (
      <StyledInputContainer>
        <StyledInputLabel text={GeneralStrings.Amount} />
        {diffBalance !== undefined && shouldShowBalance && (
          <StyledTextInfoWrapper>
            <StyledStatusText
              text={
                isIndexedSourceAccount
                  ? IndexedSavingsAccountsStrings.AvailableAmountToUse
                  : TransactionStrings.AccountStatus
              }
              hasMarginBottom
            />
            <StyledStatusText
              text={toIskCurrencyString(diffBalance, true)}
              fontSize={FontSizeRem.H2}
              fontSizeMobile={FontSizeRem.Body}
            />
          </StyledTextInfoWrapper>
        )}
        <AudurTextInput
          placeholder="kr." // TODO: Change the placeholder to kr. and current balance of withdrawal account like how it is in the app design. This change is pending on when we have remove the MUI input and migrated to custom input.
          onChange={(value: string) => setAmount(value)}
          value={amount}
          isCurrency
          autoFocus={autoFocus}
          shouldIgnoreTab={shouldIgnoreTab}
          ref={ref}
          name="transferInput"
          width="100%"
          maxLength={getTransferInputMaxLength(amount)}
        />
      </StyledInputContainer>
    );
  }
);
TransferInput.displayName = 'TransferInput';

export default TransferInput;

import React from 'react';
import {
  AccountSchema,
  ChildSchema,
  GetUsersProfileResponseSchema,
  ProductSchema,
  UserAccessSchema,
} from '@kvika/audur-api-types';
import moment from 'moment';
import AudurApiError from '@kvika/audur-api-client-v2/build/es/ApiError';
import { InterestSummary } from '@kvika/audur-api-client-v2';
import { getAccountsWithNamedFutureAccounts } from '@kvika/audur-utils';
import { FutureAccountStrings } from '@kvika/audur-strings';
import { DebouncedFunc, throttle } from 'lodash';
import { SentryErrors } from '../utils/SentryWrapper';
import AudurWebApiClient from '../audurAPI/AudurWebApiClient';
import { ActionType } from '../store/Reducers';
import { errorHandling, useAppContext } from '../store/AppContext';
import { getInitialUser } from '../utils/Utils';

type FetchUserDataState = {
  isLoading: boolean;
  fetchDataForUser: DebouncedFunc<(access?: UserAccessSchema | undefined) => Promise<void>>;
};

const useFetchUserData = (): FetchUserDataState => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { state, dispatch } = useAppContext();
  const { user } = state;

  const fetchCurrentAccess = React.useCallback(
    async (apiClient: AudurWebApiClient): Promise<UserAccessSchema | undefined> => {
      const access = await apiClient.getAudurWebApiClient().getUserAccess();
      const currentUserAccess = access.find((user) => user.isCurrent);
      if (currentUserAccess) {
        dispatch({ type: ActionType.UpdateAccess, payload: currentUserAccess }); // eslint-disable-line
      }
      return currentUserAccess;
    },
    [dispatch]
  );

  const fetchAccounts = React.useCallback(
    async (apiClient: AudurWebApiClient): Promise<void> => {
      await apiClient
        .getAudurWebApiClient()
        .getAccounts()
        .then((accountsResponse: Array<AccountSchema>) => {
          // This is done to prevent the logged in child to see its own name as the account name. Instead we provide dummy names
          const payload = user?.isChild
            ? getAccountsWithNamedFutureAccounts(accountsResponse, FutureAccountStrings.FutureAccountShort)
            : accountsResponse;
          dispatch({
            type: ActionType.UpdateAccounts,
            payload,
          });
        })
        .catch((error: AudurApiError) => {
          errorHandling({
            dispatch,
            error,
            displayErrorToUser: true,
            sentryErrorString: SentryErrors.GetAccountsError,
          });
        });
    },
    [dispatch, user?.isChild]
  );

  const fetchClosedAndDeletedAccounts = React.useCallback(
    async (apiClient: AudurWebApiClient): Promise<void> => {
      return apiClient
        .getAudurWebApiClient()
        .getClosedAndDeletedAccounts()
        .then((accountsResponse: Array<AccountSchema>) => {
          dispatch({
            type: ActionType.UpdateClosedAccounts,
            payload: accountsResponse,
          });
        })
        .catch((error: AudurApiError) => {
          errorHandling({
            dispatch,
            error,
            displayErrorToUser: true,
            sentryErrorString: SentryErrors.GetClosedAccountsError,
          });
        });
    },
    [dispatch]
  );

  const fetchInterestSummary = React.useCallback(
    async (apiClient: AudurWebApiClient): Promise<void> => {
      return apiClient
        .getAudurWebApiClient()
        .getInterestSummary()
        .then((interestSummaryResponse: InterestSummary) => {
          dispatch({
            type: ActionType.UpdateInterestSummary,
            payload: interestSummaryResponse,
          });
        })
        .catch((error: AudurApiError) => {
          errorHandling({
            dispatch,
            error,
            displayErrorToUser: true,
            sentryErrorString: SentryErrors.NoInterestSummary,
          });
        });
    },
    [dispatch]
  );

  const fetchProducts = React.useCallback(
    async (apiClient: AudurWebApiClient): Promise<void> => {
      return apiClient
        .getAudurWebApiClient()
        .getProducts()
        .then((productsResponse: Array<ProductSchema>) => {
          dispatch({
            type: ActionType.UpdateProducts,
            payload: productsResponse,
          });
        })
        .catch((error: AudurApiError) => {
          errorHandling({
            dispatch,
            error,
            displayErrorToUser: true,
            sentryErrorString: SentryErrors.NoProductsFound,
          });
        });
    },
    [dispatch]
  );

  const fetchUserProfile = React.useCallback(
    async (apiClient: AudurWebApiClient): Promise<void> => {
      return apiClient
        .getAudurWebApiClient()
        .getUserProfile()
        .then((profileResponse: GetUsersProfileResponseSchema) => {
          dispatch({ type: ActionType.UpdateUserProfile, payload: profileResponse });
          dispatch({ type: ActionType.ShowChildrensAccounts, payload: profileResponse.showChildAccounts });
          dispatch({ type: ActionType.ShowChildBecameAdult, payload: profileResponse.showChildBecameAdult });
        })
        .catch((error: AudurApiError) => {
          errorHandling({
            dispatch,
            error,
            displayErrorToUser: true,
            sentryErrorString: SentryErrors.UserProfileInvalid,
          });
        });
    },
    [dispatch]
  );

  const fetchCompanyProfile = React.useCallback(
    async (apiClient: AudurWebApiClient): Promise<void> => {
      return apiClient
        .getAudurWebApiClient()
        .getCurrentCompany()
        .then((companyResponse) => {
          dispatch({ type: ActionType.UpdateCompany, payload: companyResponse });
          dispatch({
            type: ActionType.UpdateUsersChildren,
            payload: undefined,
          });
        })
        .catch((error: AudurApiError) => {
          errorHandling({
            dispatch,
            error,
            displayErrorToUser: true,
            sentryErrorString: SentryErrors.UserProfileInvalid,
          });
        });
    },
    [dispatch]
  );

  const fetchChildren = React.useCallback(
    async (apiClient: AudurWebApiClient): Promise<void> => {
      return apiClient
        .getAudurWebApiClient()
        .getUsersChildren()
        .then((childrenResponse: Array<ChildSchema>) => {
          dispatch({
            type: ActionType.UpdateUsersChildren,
            payload: childrenResponse,
          });
        })
        .catch((error: AudurApiError) => {
          /* displayErrorToUser is false because even though this call fails we can still see the
             childrens future accounts from the getAccounts call so the site works fine you just can't
             create new future accounts and we handle that in the button */
          errorHandling({
            dispatch,
            error,
            displayErrorToUser: false,
            sentryErrorString: SentryErrors.UsersChildrenError,
          });
        });
    },
    [dispatch]
  );

  const fetchDataForUser = React.useCallback(
    async (access?: UserAccessSchema): Promise<void> => {
      setIsLoading(true);

      const expiration = moment().add(30, 'minutes');
      const apiClient = new AudurWebApiClient({ expiration, dispatch });

      dispatch({
        type: ActionType.UpdateApiClient,
        payload: apiClient,
      });

      try {
        const user = getInitialUser();
        const pepApprovalPending = user?.pepApprovalPending;
        const hasPEPdocument = Boolean(user && 'pepDocument' in user && user.pepDocument);

        const userAccess = access ?? (await fetchCurrentAccess(apiClient));
        if (userAccess) {
          dispatch({
            type: ActionType.UpdateAccess,
            payload: userAccess,
          });
        }

        if ((pepApprovalPending || hasPEPdocument) && !userAccess?.isCompany) {
          setIsLoading(false);
          return;
        }

        const fetchAccountsPromise = fetchAccounts(apiClient);
        const fetchInterestSummaryPromise = fetchInterestSummary(apiClient);
        const fetchProductsPromise = fetchProducts(apiClient);

        const promises = [fetchAccountsPromise, fetchInterestSummaryPromise, fetchProductsPromise];
        if (userAccess?.isCompany) {
          promises.push(fetchCompanyProfile(apiClient));
        } else {
          promises.push(fetchClosedAndDeletedAccounts(apiClient));
          promises.push(fetchUserProfile(apiClient));
          promises.push(fetchChildren(apiClient));
        }
        await Promise.all(promises);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        errorHandling({
          dispatch,
          error: error as AudurApiError,
          displayErrorToUser: false,
        });
      }
    },
    [
      dispatch,
      setIsLoading,
      fetchCurrentAccess,
      fetchAccounts,
      fetchChildren,
      fetchClosedAndDeletedAccounts,
      fetchCompanyProfile,
      fetchInterestSummary,
      fetchProducts,
      fetchUserProfile,
    ]
  );

  const throttledFetchDataForUser = React.useMemo(
    () => throttle(fetchDataForUser, 2000, { leading: true, trailing: false }),
    [fetchDataForUser]
  );
  return {
    isLoading,
    fetchDataForUser: throttledFetchDataForUser,
  };
};

export default useFetchUserData;

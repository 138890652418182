/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentSignatureSchema } from '@kvika/audur-api-types';
import * as React from 'react';

export type DocumentContext = {
  postDocumentSignature(documentId: number): Promise<DocumentSignatureSchema>;
  getDocumentSigningStatus(documentId: number, signatureId: number): Promise<DocumentSignatureSchema>;
  handleDocumentSigningStatus: (
    response: DocumentSignatureSchema,
    signatureId: number,
    setVerificationCode: (code: string) => void,
    handleDocumentStatusError: (errorText: string) => void,
    handleDocumentStatusSigned: () => void,
    onGetDocumentStatusRetry: (signatureId: number, finalPollingTime: Date) => void,
    finalPollingTime: Date
  ) => void;
};

const initialState = {
  postDocumentSignature: () => Promise.resolve({} as any),
  setTimeoutForPolling: () => null,
  getDocumentSigningStatus: () => Promise.resolve({} as any),
  handleDocumentSigningStatus: () => null,
};

export const DocumentSigningContext = React.createContext<DocumentContext>(initialState);

export const useDocumentSigningContext = (): DocumentContext => React.useContext(DocumentSigningContext);

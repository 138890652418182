import * as React from 'react';
import { FutureAccountStrings, TermAccountStrings, IndexedSavingsAccountsStrings } from '@kvika/audur-strings';
import { DEFAULT_NAV_ACCOUNT_LINKS, Links } from '../../../dls/DataConstants';
import FeatureWrapper from '../../development/FeatureWrapper';
import { FeatureFlag } from '../../../types/Types';
import { StyledDivider } from '../drawer/DrawerStyles';
import DrawerLink from '../drawer/DrawerLink';
import { StyledAccordianContainer } from './AccountLinksStyles';

type Props = {
  showFutureAccountsLink: boolean;
  showClosedAccountsLink: boolean;
  handleCloseDrawer: () => void;
  isOpen: boolean;
};

const AccountLinks = ({ showFutureAccountsLink, showClosedAccountsLink, handleCloseDrawer, isOpen }: Props) => {
  return (
    <StyledAccordianContainer isOpen={isOpen}>
      {DEFAULT_NAV_ACCOUNT_LINKS.map((link) => (
        <DrawerLink key={link.path} to={link.path} title={link.title} callback={handleCloseDrawer} nested />
      ))}
      {showFutureAccountsLink && (
        <DrawerLink
          to={Links.FutureAccounts.Default}
          title={FutureAccountStrings.FutureAccounts}
          callback={handleCloseDrawer}
          nested
        />
      )}
      {showClosedAccountsLink && (
        <DrawerLink
          to={Links.ClosedAccounts}
          title={TermAccountStrings.ClosedAccounts}
          callback={handleCloseDrawer}
          nested
        />
      )}
      <FeatureWrapper featureFlag={FeatureFlag.isIndexedSavingsAccountsEnabled}>
        <DrawerLink
          to={Links.IndexedSavingsAccounts.Default}
          title={IndexedSavingsAccountsStrings.IndexedSavingsAccounts}
          callback={handleCloseDrawer}
          nested
        />
      </FeatureWrapper>
      <StyledDivider />
    </StyledAccordianContainer>
  );
};
export default AccountLinks;

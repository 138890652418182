import styled from 'styled-components';

export const StyledPageImage = styled.div`
  @media all and (max-width: 56em) {
    display: none;
  }
`;

export const StyledPEPImage = styled.div`
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;

  @media all and (max-width: 45em) {
    max-width: 150px;
  }
`;

export const StyledSettingsImage = styled(StyledPageImage)`
  width: 50%;
  height: auto;
  position: relative;
`;

import styled from 'styled-components';
import { Colors, GridRem } from '../../../dls/Styleguide';

export const StyledDrawer = styled.nav<{ isOpen: boolean }>`
  background: ${Colors.Beige};
  height: 100%;
  width: 100%;
  text-align: left;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(${(props) => (props.isOpen ? '0' : '100%')}); // Slide in/out based on isOpen prop
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
`;

export const StyledDrawerContent = styled.div`
  margin: 0 ${GridRem.Gridx16};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const StyledDivider = styled.div`
  border-bottom: 1px solid ${Colors.DarkGray};
  opacity: 0.12;
`;

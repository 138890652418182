import * as React from 'react';
import styled from 'styled-components';
import { CloseSmall } from '@kvika/audur-icons';
import Link from 'next/link';
import Image from 'next/image';
import { Colors } from '@kvika/audur-theme';
import ClickableButton from '../../common/ClickableButton/ClickableButton';
import ProfileIcon from '../navigation/ProfileIcon';

import { Links } from '../../../dls/DataConstants';

type Props = {
  userName?: string;
  handleCloseDrawer: () => void;
};

const StyledMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DrawerHeader = ({ userName, handleCloseDrawer }: Props) => {
  return (
    <StyledMenuHeader>
      <Link href={Links.Home}>
        <Image width={120} height={67} src="/svg/audur_logo_dark.svg" alt="Audur logo" />
      </Link>
      <div>
        <ProfileIcon closeDrawer={handleCloseDrawer} userName={userName} />
        <ClickableButton onClick={handleCloseDrawer}>
          <CloseSmall color={Colors.DarkGray} />
        </ClickableButton>
      </div>
    </StyledMenuHeader>
  );
};
export default DrawerHeader;

import Tooltip from '@kvika/tooltip';
import styled from 'styled-components';
import { Colors, GridRem } from '../../../dls/Styleguide';
import ClickableButton from '../ClickableButton/ClickableButton';

export const StyledTooltip = styled(Tooltip).attrs(() => ({
  borderColor: Colors.LightOrange,
}))`
  background-color: ${Colors.LightOrange};
`;

export const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const StyledClickableButton = styled(ClickableButton)`
  padding: ${GridRem.Gridx4};
  align-self: flex-start;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${GridRem.Gridx8};
`;

export const StyledFlexContainer = styled.div`
  display: flex;
`;

export const StyledHeading = styled.h2`
  flex: 1;
`;

export const StyledText = styled.p`
  flex: 1;
  margin-top: 0;
  white-space: pre-wrap;
`;

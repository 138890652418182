import styled, { css } from 'styled-components';
import { ChevronLeft, ChevronRight, ChevronRightSmall } from '@kvika/audur-icons';
import { GridRem } from '../../../dls/Styleguide';

export const StyledLinkContainer = styled.div<{ nested: boolean }>`
  display: flex;
  margin: ${GridRem.Gridx16} 0 ${GridRem.Gridx8} 0;

  ${(props) =>
    props.nested
      ? css`
          gap: ${GridRem.Gridx16};
        `
      : css`
          justify-content: space-between;
          align-items: center;
        `};
`;

const getIconStyles = () => {
  return css`
    margin-top: auto;
    margin-bottom: auto;
  `;
};

export const StyledChevronRight = styled(ChevronRight)`
  ${getIconStyles}
`;

export const StyledChevronRightSmall = styled(ChevronRightSmall)`
  ${getIconStyles}
`;

export const StyledChevronLeft = styled(ChevronLeft)`
  ${getIconStyles}
`;

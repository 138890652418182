import * as React from 'react';
import { Colors } from '../../dls/Styleguide';
import { useSurveyContext } from '../../store/SurveyContext';
import { progressBarLoader } from '../../utils/ContentLoaders';
import { showAddressStep } from '../../utils/Utils';

type Props = {
  currentStep: number;
  className?: string;
  isLivingAbroad: boolean;
};

const ProgressBar = ({ currentStep, className, isLivingAbroad }: Props) => {
  const { state } = useSurveyContext();
  const { survey, answers } = state;

  const surveyStepCount = survey.questionGroups?.length ?? 6;
  const stepCount = showAddressStep(answers, isLivingAbroad) ? surveyStepCount + 4 : surveyStepCount + 3;
  const progressData = new Array(stepCount);

  for (let index = 0; index < stepCount; index += 1) {
    progressData.push(currentStep >= index);
  }

  return (
    <div className={className}>
      {survey.questionGroups?.length < 1
        ? progressBarLoader('progressBarLoader')
        : progressData.map((visited, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              style={{
                height: 2,
                flex: 1,
                borderRadius: 8,
                backgroundColor: visited ? Colors.LightOrange : Colors.Beige,
                // last element without margin right
                marginRight: progressData.length - 1 === index ? 0 : 10,
              }}
            />
          ))}
    </div>
  );
};

export default ProgressBar;

import styled from 'styled-components';
import { Colors, GridRem } from '../../../dls/Styleguide';
import { TextInfo } from '../../common';

export const StyledInfoCard = styled.div`
  background-color: ${Colors.LightCardGray};
  border-radius: 4px;
  padding: ${GridRem.Gridx16};
`;

export const StyledTitle = styled(TextInfo)`
  width: 75%;
  margin-bottom: ${GridRem.Gridx16};
`;

export const StyledSwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${GridRem.Gridx16};
`;

export const StyledSwitchText = styled(TextInfo)`
  max-width: 14rem;
`;

import React from 'react';
import Link from 'next/link';
import { Links } from '../../../dls/DataConstants';
import { StyledIconButton, StyledIconButtonText } from './ProfileIconStyles';

type Props = {
  userName?: string;
  closeDrawer?: () => void;
};

const ProfileIcon = ({ userName, closeDrawer }: Props) => {
  return (
    <Link href={Links.Settings} passHref>
      <StyledIconButton onClick={closeDrawer}>
        <StyledIconButtonText data-testid="profile-icon">{userName?.charAt(0)}</StyledIconButtonText>
      </StyledIconButton>
    </Link>
  );
};

export default ProfileIcon;

import styled from 'styled-components';
import Link from 'next/link';
import Image from 'next/image';
import { Colors, GridRem } from '../../dls/Styleguide';
import TextInfo from '../common/TextInfo';

export const StyledNavContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;

  @media all and (max-width: 719px) {
    justify-content: flex-end;
  }
`;

export const StyledNavLinks = styled.div`
  display: flex;
  margin-left: ${GridRem.Gridx32};
  align-items: center;

  @media all and (max-width: 719px) {
    display: none;
  }
`;

export const StyledNavContainer = styled.nav`
  display: flex;
  align-items: center;
  margin-bottom: ${GridRem.Gridx32};
  border-bottom: 2px solid ${Colors.CardGray};
`;

export const StyledImageLink = styled(Link)`
  margin-left: ${GridRem.Gridx16};
`;

export const StyledImage = styled(Image)`
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  margin-right: ${GridRem.Gridx32};
`;

export const StyledRightContent = styled.div`
  display: flex;
  margin-right: ${GridRem.Gridx16};
  align-items: center;
`;

export const StyledLinkText = styled(TextInfo)<{ selected: boolean }>`
  padding-bottom: ${GridRem.Gridx4};
  border-bottom: ${({ selected }) => (selected ? `2px solid ${Colors.Beige}` : '2px solid transparent')};
`;

export const StyledTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GridRem.Gridx8};
  padding: ${GridRem.Gridx8};
`;

import KvikaApiClient from '@kvika/api-client';

class KvikaWebApiClient {
  private kvikaClient: KvikaApiClient;

  constructor(authToken?: string) {
    this.kvikaClient = new KvikaApiClient({
      apiUrl: process.env.NEXT_PUBLIC_KVIKA_API_URL ?? '',
      storeAuthToken: () => {
        // Leave empty as we don't need to store auth token in web, it's done via secure cookies
      },
      appToken: process.env.NEXT_PUBLIC_AUDUR_APP_TOKEN ?? '',
      authToken,
    });
  }

  public getKvikaWebApiClient() {
    return this.kvikaClient;
  }
}

export default KvikaWebApiClient;

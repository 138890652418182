/**
 * Wrapper for browser storage functions to check if we have the window object before continuing.
 * getItem returns string or null
 * setItem, removeItem, etc. return void
 * @param fn - storage function to wrap (e.g. window.sessionStorage.getItem)
 */
export const withWindowDefined = (fn: () => string | null | void) => {
  if (typeof window !== 'undefined') {
    return fn();
  }
  return undefined;
};

import * as React from 'react';
import Link from 'next/link';
import { Colors } from '../../dls/Styleguide';
import { StyledTextInfo } from './DropdownLinkStyles';

type Props = {
  path: string;
  title: string;
  currentPath: string;
};

const DropDownLink = ({ path, title, currentPath }: Props) => (
  <Link href={path}>
    <StyledTextInfo text={title} color={Colors.DarkGray} bold selected={currentPath === path} />
  </Link>
);

export default DropDownLink;

import * as React from 'react';
import { Colors } from '../../dls/Styleguide';
import { StyledHeading, StyledMobileSpan, StyledSpan } from '../styledComponents/HeadingStyles';

type Props = {
  text: string;
  textMobile?: string;
  textColor?: Colors;
  marginLeft?: string;
  style?: React.CSSProperties;
  className?: string;
};

const Heading = ({ text, textMobile, textColor, marginLeft, style, className }: Props) => {
  return (
    <StyledHeading
      style={{
        color: textColor || Colors.Beige,
        marginLeft,
        ...style,
      }}
      className={className}
    >
      <StyledSpan>{text}</StyledSpan>
      <StyledMobileSpan>{textMobile}</StyledMobileSpan>
    </StyledHeading>
  );
};

export default Heading;

import { UnknownObject } from '../types/Types';
import { getPathnameForAnalytics } from './Utils';

declare const window: UnknownObject;
declare const global: UnknownObject;

export enum SegmentTrackingId {
  LoginStarted = 'User Login Inititated',
  LoginCompleted = 'User Login Completed',
  LoginFailed = 'User Login Failed',
  Logout = 'User Logged Out',
  RegistrationStarted = 'Registration Started',
  RegistrationCompleted = 'Registration Completed',
  RegistrationFailed = 'Registration Failed',
  RegistrationPEP = 'Registration PEP Confirmed',
  OnboardingStep = 'Onboarding Step XX started',
  TermsViewed = 'Terms Viewed',
  TermsSigned = 'Terms Signed',
  WithdrawalCompleted = 'Withdrawal Completed',
  TermAccountCreated = 'Term Account Created',
  FutureAccountCreated = 'Future Account Created',
  FutureAccountCreationFailed = 'Future Account Creation Failed',
  EIDLoginMethod = 'EID',
  ESIMLoginMethod = 'ESIM',
  RegistrationDocumentSignStarted = 'Registration document sign started',
  RegistrationDocumentSignFailed = 'Registration document sign failed',
  RegistrationDocumentSignCompleted = 'Registration document sign completed',
  RegistrationDocumentAlreadySigned = 'Registration document already signed',
}

export enum SegmentPageId {
  Login = 'Login',
  Home = 'Home',
  CreateAccount = 'Create Account',
  Deposit = 'Deposit',
  Withdraw = 'Withdraw',
  SavingsAccount = 'Savings Account',
  TermAccounts = 'Term Deposit Accounts',
  Transactions = 'Transactions',
  Settings = 'Settings',
}

export type AnalyticsEvent = {
  event: SegmentTrackingId | SegmentPageId;
  properties?: Record<string, unknown>;
};

export const trackPage = (url: string, properties?: Record<string, unknown>): void => {
  window.analytics.page(getPathnameForAnalytics(url), properties);
};

export const trackEvent = (analyticsEvent: AnalyticsEvent): void => {
  global.analytics.track(analyticsEvent.event, analyticsEvent.properties);
};

export const identifyUser = (guid: string, traits?: Record<string, unknown>): void => {
  global.analytics.identify(guid, traits);
};

import { ApiError as KvikaApiError } from '@kvika/api-client';
import { AnswerWriteSchema, DisruptionTypeEnum } from '@kvika/api-types';
import {
  AudurMarketInterestRatesSavingsMarketInterestRates,
  AudurMarketInterestRatesTermDepositMarketInterestRates,
  AudurMarketInterestRatesFutureMarketInterestRates,
  Scalars,
  Maybe,
} from '@kvika/audur-prismic-types';
import { ApiError as AudurApiError } from '@kvika/audur-api-client-v2';
import {
  AccountNumberSchema,
  AccountSchema,
  AddressSchema,
  ChildSchema,
  AudurCompanySchema,
  GetUsersProfileResponseSchema,
  LoginResponseSchema,
  ProductSchema,
  TransactionSchema,
  UserAccessSchema,
} from '@kvika/audur-api-types';
import AudurWebApiClient from '../audurAPI/AudurWebApiClient';
import { IYearInterestSummary } from './APITypes';
import { SegmentPageId, SegmentTrackingId } from '../utils/AudurAnalytics';
/**
 * Accepts an object type T and returns a union of all of its key/value pairs.
 * E.g. if we pass in the type { id: number; email?: string} we get
 * { id: number; } | { email?: string | undefined; }
 */
export type UnionOfSingleKeyObjects<T extends object> = {
  /**
   * [K in keyof T] = For each key K in keyof T (all the keys in the object type).
   *
   * -? = Remove the optional modifier. Without this, if any key in the object T was e.g "email?",
   * then the return value from this function would be a union that ends with "| undefined".
   * Adding this ensures that the returned union is always the key/value pairs
   * (even if some key was marked as optional).
   *
   * { [P in K]: T[P] } = Calculate the single-key object in question for each key (see below)
   *
   * [P in K] = [P in K] gets the value P of the literal type K.
   * Even if we have just one key (like K is here), we have to write [P in K] for it to work.
   * This throws an error if it's just [K] since we must have a value, not a type, in the square brackets
   * (and additionally the type of that value must be a literal or unique symbol).
   *
   * T[P] = Gets the key/value pair of T at index P
   */
  [K in keyof T]-?: { [P in K]: T[P] };
  /**
   * [keyof T] = Index into the mapped type above to get the union of all single-key object types.
   * Without this we would get e.g. { id: { id: number; }; email: { email: string; }; ... }
   * This is because the part before the ":" is also part of the returned mapped type above.
   * With this we get { id: number; } | {email: string} | ...
   */
}[keyof T];

export interface IAudurPin {
  pinNumber1: string;
  pinNumber2: string;
  pinNumber3: string;
  pinNumber4: string;
}

export type AudurKeyboard = {
  isShowingKeyboard: boolean;
  keyboardHeight: number;
};

export type InformationModal = {
  height: number;
  isOpen: boolean;
  modalChildren: JSX.Element | JSX.Element[];
};

export type ApiErrorWrapper = {
  displayErrorToUser: boolean;
  apiError?: AudurApiError | KvikaApiError;
};

export type RegistrationInfo = {
  email: string;
  address: AddressSchema;
  externalAccount: AccountNumberSchema;
  isPEP: boolean;
};

export type RegistrationInfoItem = UnionOfSingleKeyObjects<RegistrationInfo>;

export interface PrismicData {
  savingsMarketInterestRates: AudurMarketInterestRatesSavingsMarketInterestRates;
  termDepositMarketInterestRates: AudurMarketInterestRatesTermDepositMarketInterestRates;
  futureMarketInterestRates: AudurMarketInterestRatesFutureMarketInterestRates;
  inflationRateGoal: Maybe<Scalars['Float']>;
  marketInterestRatesCompany: AudurMarketInterestRatesSavingsMarketInterestRates;
}

export type ServiceStatusMode = {
  serviceStatus?: DisruptionTypeEnum;
  message?: string;
  showAfter?: Date;
};

export type AppState = {
  apiClient: AudurWebApiClient;
  audurError: ApiErrorWrapper;
  accounts: Array<AccountSchema>;
  closedAccounts: Array<AccountSchema>;
  interestSummary: IYearInterestSummary | undefined;
  products: Array<ProductSchema>;
  user: LoginResponseSchema | undefined;
  userProfile: GetUsersProfileResponseSchema | undefined;
  phoneNumber: string | undefined;
  registrationInfo: RegistrationInfo;
  prismicData: PrismicData;
  usersChildren: Array<ChildSchema> | undefined;
  serviceStatusMode: ServiceStatusMode;
  showChildrensAccounts: boolean;
  showChildBecameAdult: boolean;

  loading: {
    isLoadingAccounts: boolean;
    isLoadingInterestSummary: boolean;
    isLoadingProducts: boolean;
  };
  isCompanyEntity: boolean;
  companyInfo: AudurCompanySchema | undefined;
  access: UserAccessSchema | undefined;
};

export type FutureAccountsState = {
  surveyAnswers: AnswerWriteSchema[];
};

export interface IAudurQuestionOption {
  key: string;
  value: string;
  ID: number;
}

export type CalculatedEarnings = {
  balance: number;
  interest: number;
};

export enum DeviceSize {
  Small,
  Medium,
  Large,
}

export interface StyleSheet {
  [key: string]: React.CSSProperties;
}

export type IsLoading = {
  isLoadingAccounts: boolean;
  isLoadingInterestSummary: boolean;
  isLoadingProducts: boolean;
  isLoadingLogin: boolean;
};

export type AnalyticsEvent = {
  event: SegmentTrackingId | SegmentPageId;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties?: any;
};

export interface LocationState {
  from: {
    pathname: string;
  };
}

export enum LinkTarget {
  Self = '_self',
  Blank = '_blank',
  Parent = '_parent',
  Top = '_top',
}

export type ActionMap<M extends { [index: string]: unknown }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type UserWithouthAuthToken = Omit<LoginResponseSchema, 'authorizationToken'>;

// eslint-disable-next-line
export type UnknownObject = { [key: string]: any };

export type GroupedTransactions = {
  [key: string]: Array<TransactionSchema>;
};

export type LoginStatusProps = {
  loginRequestToken?: string;
  firstTime?: number;
  secondTime?: number;
  finalPollingTime: Date;
};

export enum LoginStep {
  Idle,
  Started,
  Success,
  Pending,
  Error,
}

export type ErrorData = {
  detail?: string;
  code?: number | string;
  type?: string;
};

export enum TransferSlide {
  DestinationAccountSelection,
  SourceAccountSelection,
  TransferMoney,
  Success,
}

export enum FeatureFlag {
  isIndexedSavingsAccountsEnabled,
}

export enum LoginIntent {
  individual = 'individual',
  company = 'company',
}

export type ExternalAccount = { id: 'external'; accountNumber: AccountNumberSchema };

// Accounts when transferring money can be either an Auður bank account or the external account (or none selected yet)
export type SelectedAccount = AccountSchema | ExternalAccount | undefined;

import * as React from 'react';
import { Document } from '@kvika/audur-icons';
import { GeneralStrings, TermAccountStrings } from '@kvika/audur-strings';
import Button from '@kvika/audur-button';
import Toggle from '@kvika/audur-toggle';
import { ModalSize } from '@kvika/audur-modal';
import { GridRem, FontSizeRem } from '../../../dls/Styleguide';
import { TextInfo, AudurModal } from '../../common';
import { ignoreTab } from '../../../utils/Utils';
import { StyledInfoCard, StyledSwitchContainer, StyledSwitchText, StyledTitle } from './TermsAndConditionsStyles';

type Props = {
  title: string;
  terms: Array<string>;
  hasReadTerms: boolean;
  setHasReadTerms(toggled: boolean): void;
  shouldIgnoreTab?: boolean;
};

const TermsAndConditions = ({ title, terms, hasReadTerms, setHasReadTerms, shouldIgnoreTab }: Props) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = () => {
    setHasReadTerms(!hasReadTerms);
  };

  return (
    <div>
      <StyledInfoCard>
        <TextInfo text={GeneralStrings.Conditions} fontSize={FontSizeRem.H2} bold />
        <StyledTitle text={title} />
        <Button
          icon={<Document />}
          iconPlacement="left"
          variant="outlined"
          onClick={handleOpen}
          width="100%"
          {...(shouldIgnoreTab && { onKeyDown: (event) => ignoreTab(event) })}
        >
          {GeneralStrings.ReadConditions}
        </Button>
      </StyledInfoCard>
      <StyledSwitchContainer>
        <StyledSwitchText text={TermAccountStrings.SwitchConfirmationText} />
        <Toggle isChecked={hasReadTerms} onChange={onChange} />
      </StyledSwitchContainer>
      <AudurModal scrollable isOpen={open} handleClose={handleClose} title={title} size={ModalSize.LONG}>
        <div style={{ overflowY: 'scroll', marginTop: GridRem.Gridx8, padding: GridRem.Gridx16 }}>
          {terms.map((paragraph) => {
            return <TextInfo text={paragraph} style={{ marginTop: GridRem.Gridx8 }} key={paragraph} />;
          })}
        </div>
      </AudurModal>
    </div>
  );
};

export default TermsAndConditions;

import Button from '@kvika/audur-button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalSize } from '@kvika/audur-modal';
import { GeneralStrings } from '@kvika/audur-strings';
import * as React from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import FontWrapper from '../../styles/FontWrapper';
import Shapes from '../../../public/lottie/loader_shapes.json';

type Props = {
  isOpen: boolean;
  title: string;
  handleClose?(): void;
  children: React.ReactNode;
  continueText?: string;
  onContinue?(): void;
  backText?: string;
  onBack?(): void;
  size?: ModalSize;
  isLoading?: boolean;
  showCloseButton?: boolean;
  closeOnOutsideClick?: boolean;
  scrollable?: boolean;
  className?: string;
  showLoader?: boolean;
};

const StyledModalHeader = styled(ModalHeader)<{ title?: string }>`
  height: ${(props) => !props.title && 0};
`;

const AudurModal = ({
  isOpen,
  title,
  handleClose,
  children,
  size = ModalSize.SHORT,
  continueText: callToActionText = GeneralStrings.Forward,
  onContinue: onCallToAction,
  backText = GeneralStrings.Back,
  onBack,
  isLoading,
  showCloseButton = true,
  closeOnOutsideClick = true,
  scrollable = false,
  className,
  showLoader = false,
}: Props) => {
  if (typeof window === 'undefined') return null; // We need to make sure that window is defined before rendering the modal since the modal uses event listeners which depend on window being defined. If this is removed, all pages with a modal will break when trying to SSR

  const showFooter = onCallToAction || onBack;
  return (
    <Modal
      isVisible={isOpen}
      onClose={handleClose}
      size={size}
      closeOnClickOutside={closeOnOutsideClick}
      className={className}
    >
      {title && (
        <StyledModalHeader showCloseButton={showCloseButton} title={title}>
          <FontWrapper>{title}</FontWrapper>
        </StyledModalHeader>
      )}

      <ModalBody scrollable={scrollable} {...(!title && { showCloseButton })}>
        <ModalChildrenWrapper>
          <FontWrapper>{children}</FontWrapper>
        </ModalChildrenWrapper>
      </ModalBody>
      {showFooter && (
        <ModalFooter>
          <Button onClick={onBack} variant="outlined">
            {backText}
          </Button>
          <Button onClick={onCallToAction} variant="outlined" loading={isLoading}>
            {callToActionText}
          </Button>
        </ModalFooter>
      )}
      {showLoader && <StyledLottie animationData={Shapes} loop />}
    </Modal>
  );
};

const ModalChildrenWrapper = styled.div`
  overflow: auto;
`;

const StyledLottie = styled(Lottie)`
  max-width: 187px;
  align-self: center;
`;

export default AudurModal;

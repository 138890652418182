import * as React from 'react';
import Image from 'next/image';

import styled from 'styled-components';
import { FooterStrings } from '@kvika/audur-strings';

import FooterLink from './FooterLink';
import { Colors } from '../../../dls/Styleguide';
import { StyledContactColumn, StyledTextContainer } from './FooterStyles';

const StyledSpan = styled.span`
  line-height: 2;
  letter-spacing: 0.5px;
  color: ${Colors.Beige};
`;

const ContactColumn = () => {
  return (
    <StyledContactColumn>
      <StyledTextContainer>
        <Image src="/svg/email.svg" alt="email" height={50} width={50} />
        <FooterLink url={`mailto:${FooterStrings.AudurEmail}`} title={FooterStrings.AudurEmail} />
      </StyledTextContainer>
      <StyledTextContainer>
        <Image src="/svg/building.svg" alt="address" height={50} width={50} />
        <StyledSpan>{FooterStrings.AudurAddress}</StyledSpan>
      </StyledTextContainer>
      <StyledTextContainer>
        <Image src="/svg/phone.svg" alt="phone" height={50} width={50} />
        <FooterLink url={`tel:+354${FooterStrings.AudurPhone}`} title={FooterStrings.AudurPhone} />
      </StyledTextContainer>
    </StyledContactColumn>
  );
};

export default ContactColumn;

import styled from 'styled-components';
import TextInfo from '../TextInfo';
import { GridRem } from '../../../dls/Styleguide';

export const StyledInputContainer = styled.div`
  position: relative;
`;

export const StyledInputLabel = styled(TextInfo)`
  position: absolute;
  bottom: 39px;
  margin-bottom: ${GridRem.Gridx4};
`;

export const StyledStatusText = styled(TextInfo)<{ hasMarginBottom?: boolean }>`
  opacity: 0.4;
  text-align: right;
  margin-right: ${GridRem.Gridx4};
  margin-bottom: ${({ hasMarginBottom }) => (hasMarginBottom ? GridRem.Gridx4 : 0)};
`;
